/* eslint-disable no-debugger, no-console */
import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: {},
    currentRestaurant: {},
    currentCheckIn: undefined,
    checkinHistory: [],
    checkinHistoryLoading: false
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    
    setCurrentRestaurant(state, val) {
      state.currentRestaurant = val
    },

    setCurrentCheckIn(state, val) {
      state.currentCheckIn = val
    },

    setCheckinHistory(state, val) {
      state.checkinHistory = val
    },

    setCheckinHistoryLoading(state, val) {
      state.checkinHistoryLoading = val
    }
  },
  actions: {
    async setCurrentUser({commit}, user) {
      const guestDoc = await fb.guestsCollection.doc(user.uid).get()
      // if(!guestRef.exists){
      //   await guestRef.set({phone: user.phoneNumber }, {merge: true})
      // }
      
      let guest = {}
      if(guestDoc.exists) {
        guest = {
          ...{ id: guestDoc.id },
          ...guestDoc.data()
        }
      } else {
        guest = {
          ...this.state.userProfile,
          ...{
            phone: user.phoneNumber,
            id: user.uid
          }
        }
      }

      commit('setUserProfile', guest)

      return guest
    },

    async updateProfile({ commit, state }, data) {      
      // const guestRef = await fb.guestsCollection.doc(state.userProfile.id);
      // await guestRef.set({firstname: data.firstname, lastname: data.lastname}, {merge: true})
      // var guest = await guestRef.get()
      var guest = {
        id: state.userProfile.id,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: state.userProfile.phone
      }
      commit('setUserProfile', guest)
      return guest
    },

    async fetchExistingCheckin({commit, state}) {
      const restaurantSeatingTime = state.currentRestaurant.estimatedSeatingTime
      const now = moment();
      const start = moment().subtract(restaurantSeatingTime, 'minutes');
      
      //If state is already set and it is still valid, return it
      if(state.currentCheckIn && state.currentCheckIn.arrival && state.currentCheckIn.arrival.toDate() >= start) return state.currentCheckIn
      
      //Search store for a valid checkin
      let checkInQuery = await fb.checkInCollection
        .where("restaurantId", "==", state.currentRestaurant.id)
        .where("guestId", "==", state.userProfile.id)
        .where("arrival", ">=", fb.fb.firestore.Timestamp.fromDate(start.toDate()))
        .where("arrival", "<=", fb.fb.firestore.Timestamp.fromDate(now.toDate()))
        .get()

      let checkIn = undefined
      if(!checkInQuery.empty) {
        checkInQuery.forEach((doc) => {
          checkIn = doc.data()
        })
      }

      commit('setCurrentCheckIn', checkIn)
      return checkIn
    },

    async fetchCheckinHistory({commit, state}) {
      commit('setCheckinHistoryLoading', true)
      //Search store for historic checkins
      let checkInQuery = await fb.checkInCollection
        .where("guestId", "==", state.userProfile.id)
        .get()
        
      let history = []
      if(!checkInQuery.empty) {
        checkInQuery.forEach((doc) => {
          history.push({
            ... { id: doc.id },
            ...doc.data()
          });
        })
      }

      commit('setCheckinHistory', history)
      commit('setCheckinHistoryLoading', false)
      return history;
    },

    async storeUserProfile({commit, state}) {
      const guestRef = await fb.guestsCollection.doc(state.userProfile.id)
      
      if(!guestRef.exists){
        await guestRef.set({
          phone: state.userProfile.phone,
          firstname: state.userProfile.firstname,
          lastname: state.userProfile.lastname,
        }, {merge: true})
      }

      var guest = await guestRef.get()

      commit('setUserProfile', {
        ... { id: guest.id },
        ...guest.data()
      })
      return guest.data()
    },

    async getTermsAccepted({state}) {
      //If already stored in state
      if(state.currentRestaurant.termsAccepted !== undefined) {
        return state.currentRestaurant.termsAccepted 
      }

      const termsQuery = await fb.termsCollection
      .where("restaurantId", "==", state.currentRestaurant.id)
      .where("guestId", "==", state.userProfile.id)
      .get()

      this.commit("setCurrentRestaurant", {
        ...state.currentRestaurant,
        ... {
          termsAccepted: !termsQuery.empty
        }
      })

      return !termsQuery.empty
      
    },

    async storeTermsAccepted({commit, dispatch, state}) {
      const termsAccepted = await dispatch("getTermsAccepted")
      if(!termsAccepted) {
        let termsRef = fb.termsCollection.doc()
        await termsRef.set({
          restaurantId: state.currentRestaurant.id,
          guestId: state.userProfile.id
        })

        commit("setCurrentRestaurant", {
          ...state.currentRestaurant,
          ... {
            termsAccepted: true
          }
        })
      }
    },

    async checkInCurrentUser({commit, state, dispatch}) {
      try {
        await dispatch('storeUserProfile')
        await dispatch('storeTermsAccepted')

        var existing = await dispatch('fetchExistingCheckin')        
        if(existing) return existing;

        const now = moment();
        const expires = moment().add(state.currentRestaurant.estimatedSeatingTime, 'minutes');

        let checkInRef = fb.checkInCollection.doc()

        await checkInRef.set({
          restaurantId: state.currentRestaurant.id,
          restaurantName: state.currentRestaurant.name,
          guestId: state.userProfile.id,
          guestPhone: state.userProfile.phone,
          guestFirstName: state.userProfile.firstname,
          guestLastName: state.userProfile.lastname,
          arrival: fb.fb.firestore.Timestamp.fromDate(now.toDate()),
          expires: fb.fb.firestore.Timestamp.fromDate(expires.toDate())
        })

        let checkInd = await checkInRef.get()
        let checkIn = checkInd.data()
        
        commit('setCurrentCheckIn', checkIn)
        return checkIn
      } catch(err) {
        console.log(err)
      }
    },
    
    async deleteProfile({commit, state}) {
      await fb.guestsCollection.doc(state.userProfile.id).delete()
      await fb.auth.signOut();

      commit('setUserProfile', {})
    },

    async logout({commit}) {
      await fb.auth.signOut();

      commit('setUserProfile', {})
    },

    async setCurrentRestaurant({commit, dispatch}, restaurantId) { 
      const restaurant = await fb.restaurantsCollection.doc(restaurantId).get();
      
      if(!restaurant.exists) throw 'Restaurant doesn\'t exists';
      
      let logoUrl = ''
      try {
        const logoRef = await fb.storage.ref(`logos/${restaurantId}.png`);
        logoUrl = await logoRef.getDownloadURL()
      } catch (err) {
        //file doesn't exist
      }

      commit('setCurrentRestaurant', {
        ... { id: restaurant.id, logoUrl },
        ...restaurant.data()          
      })
      await dispatch('getTermsAccepted')
    }
  },
  modules: {
  }
})
