/* eslint-disable no-debugger, no-console */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Welcome from '../views/Welcome.vue'
import CheckedIn from '../views/CheckedIn.vue'
import CheckIn from '../views/CheckIn.vue'
import Login from '../views/Login.vue'
import UpdateProfile from '../views/UpdateProfile.vue'
import Profile from '../views/Profile.vue'
import { auth } from '../firebase'
import store from '../store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },
  {
    name: 'restaurant-not-found',
    path: '/:restaurantId/not-found',
    component: NotFound,
  },
  {
    name: 'login',
    path: '/login/:restaurantId?',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (auth.currentUser) {
        if(to.params.restaurantId){
          next({name: 'checkin', params: {
            restaurantId: to.params.restaurantId
          }})
        }
        else {
          next({name: 'profile'})
        }
      } else {
        next()
      }
    }
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (!auth.currentUser) {
        next({name: 'login'})
      } else {
        store.dispatch('setCurrentUser', auth.currentUser).then(() => {
          next()
        }, (err) => {
          console.log(err)
          next({name: 'Welcome'})
        })
      }
    }
  },
  {
    path: '/:restaurantId',    
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!auth.currentUser) {
        next({name: 'login', params: {
          restaurantId: to.params.restaurantId
        }})
      } else {       
        store.dispatch('setCurrentUser', auth.currentUser).then(() => { 
          store.dispatch('setCurrentRestaurant', to.params.restaurantId).then(() => {
            next()
          }, () => {
            next({name: 'restaurant-not-found', params: {
              restaurantId: to.params.restaurantId
            }})
          })
        })
      }
    },
    children: [
      {
        name: 'updateprofile',
        path: 'update-profile',
        component: UpdateProfile,
      },
      {
        name: 'checkedin',
        path: 'checkedin',
        component: CheckedIn,
        beforeEnter: (to, from, next) => {
          store.dispatch('fetchExistingCheckin').then((existing) => {
            if(existing && existing.arrival) {
              next()
            }
            else {
              next({name: 'checkin', params: {
                restaurantId: to.params.restaurantId
              }})
            }
          })
        }
      },
      {
        name: 'checkin',
        path: '',
        component: CheckIn,
        beforeEnter: (to, from, next) => {
          let guest = store.state.userProfile
          if(!guest.firstname || guest.firstname === '' || !guest.lastname || guest.lastname === '')
          {
            next({name: 'updateprofile', params: {
              restaurantId: to.params.restaurantId
            }})
          } else {
            //Check if already has existing valid checkin
            store.dispatch('fetchExistingCheckin').then((existing) => {
              if(!existing) {
                next()
              } else {
                next({name: 'checkedin', params: {
                  restaurantId: to.params.restaurantId
                }})
              }
            })
          }
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
