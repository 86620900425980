<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5">
        <v-alert prominent type="warning">          
          Denne restauranten er ikke registrert. Ta kontakt med betjeningen
        </v-alert>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      
    }),

    methods: {
      logout() {
        this.$store.dispatch('logout')
        this.$router.push('/')
      }
    }
  }
</script>
