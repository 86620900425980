<template>
  <div class="home">
    <p>Hvordan bruker jeg denne appen?</p>
    <p>På ditt utested er det utplassert en QR kode. Du kan scanne denne med din mobiltelefon, og deretter sjekke inn på ditt utested</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Welcome',
}
</script>
