<template>
  <v-container>
    <v-row v-if="!currentRestaurant.termsAccepted">
      <v-col>
        <v-checkbox large :readonly="!agreement" v-model="agreement" @click.stop.prevent="dialog = true">
          <template v-slot:label>
            <p>Jeg har lest <a href="#" @click.stop.prevent="dialog = true">personvernerklæringen</a> og godkjenner at mine opplysninger blir delt med <b>{{currentRestaurant.name}}</b></p>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn :disabled="!currentRestaurant.termsAccepted && !agreement" large color="primary" class="pa-16" @click="checkin">
          <h1>SJEKK INN</h1>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn v-if="currentRestaurant.termsAccepted" :to="{name: 'profile'}"> 
          Min profil
        </v-btn>
      </v-col>
    </v-row>
      
      
      <v-dialog
      v-model="dialog"
      absolute
      max-width="400"
      persistent
      fullscreen
    >
    <v-card>
        <v-card-title class="headline grey lighten-3">Personvernerklæring</v-card-title>
        <v-card-text align="left">
          
<br />
<h2>Innledning</h2>
<p><b>{{currentRestaurant.name}}</b> (videre nevnt dataansvarlig) benytter seg av en tjeneste (videre nevnt løsningen) utviklet av Callida AS for å registrere besøkende gjester hos <b>{{currentRestaurant.name}}</b>.</p>

<h2>Innsamling og sletting av opplysninger</h2>
<p>Gjennom å akseptere personvernerklæringen godkjenner du at dataansvarlig lagrer opplysninger i forbindelse med ditt besøk hos <b>{{currentRestaurant.name}}</b></p>

<h3>Dataansvarlig vil lagre:</h3>
<ul>
  <li>Profilopplysninger</li>
  <li>Fornavn | Etternavn | Telefonnummer</li>
  <li>Besøksopplysninger</li>
  <li>Besøksdato og tidspunkt for “incheck” hos dataansvarlig.</li>
</ul>


<br />
<p>Opplysning om ditt besøk lagres i 14 dager og vil deretter automatisk slettes og vil ikke kunne gjenopprettes.
Øvrige opplysninger vil bli slettet etter oppfordring fra bruker av løsningen senest 14 dager etter siste innsjekk ved en lokasjon som benytter seg av løsningen.</p>

<h2>Formål med innsamling</h2>
<p>Dataansvarlig ønsker å verne om deg som gjest i forbindelse med situasjoner som kan påvirke din og andre gjesters helse i forbindelse med ditt besøk hos dataansvarlig.
Dataansvarlig har et behov for å strukturere, sikre og fremlegge informasjon om besøkende gjester en i tidsperiode dersom det foreligger mistanke om oppstått smitteutbrudd/situasjon hos dataansvarlig der det kan være aktuelt å kunne identifisere og kontakte gjester som har befunnet seg på samme sted i samme tidsrom eller av andre årsaker trenger å kontaktes.</p>

<p>Dataansvarlig ønsker også gjennom løsningen sikre og garantere deg sletting av opplysninger dersom data har blitt irrelevant for formålet og/eller etter ønske sletting av opplysninger.</p>

<h2>Disclosure/Deling av opplysninger</h2>
<p>Dataansvarlig vil dele nødvendige opplysninger med:</p>
<ul>
  <li>For formålet utpekte myndigheter for å ivareta nødvendig sporing av oppstått smitteutbrudd/situasjon for incheckede lokasjoner.</li>
  <li>Callida AS, opplysninger vil brukes i løsningen med formål i å skape mest mulig effektiv løsning for deg som gjest på de lokasjoner som benytter seg av løsningen og i forbindelse med å raskt kunne bistå i myndigheters arbeid med smitteoppsporing.</li>
</ul>


<br />
<h2>Informasjonskapsler</h2>
<p>Løsningen benytter seg av informasjonskapsler s.k “Cookies” for å øke brukeropplevelsen av løsningen.
Ved bruk av funksjonaliteten “Husk meg” vil løsningen lagre data på din enhet for å forenkle fremtidige incheckninger på samme lokasjon og/eller ved lokasjoner som benytter seg av løsningen der du som bruker godkjent lokasjonens personvernserklæring.</p>

<h2>Sikkerhet</h2>
<p>Løsningen benytter seg av tofaktorautentisering gjennom sms for å sikre så langt det er mulig at det er innehaveren av telefonen som checker in ved lokasjonen.
Opplysninger blir lagret i skyen (Google Cloud Platform) og sikres gjennom nødvendige tjenester som tilbys av leverandøren.
Dataansvarligs tilgang for rapporter i forbindelse med smittesporing gis adgang gjennom personlig brukerkonto og sikres bak en kombinasjon med sterkt passord (min.12 tegn) og tofaktorautentisering.</p>

<h2>Dine rettigheter og innsyn</h2>
<p>Du som bruker har i din profil alltid oversikt over hvilken data som er lagret på deg og vil i din profil alltid ha mulighet til å velge “Glem meg” for å få dine data slettet.
Data vil bli slettet basert på reglene som står spesifisert i stykket “Innsamling og sletting av opplysninger”</p>

<h2>Dataansvarligs kontakt</h2>
<p>Ved spørsmål og eller hjelp i forbindelse med løsningen og eller behov av hjelp kontakt:<br />
  <b>{{currentRestaurant.contactFirst}} {{currentRestaurant.contactLast}} <br />
  {{currentRestaurant.contactPhone}} <br />
  {{currentRestaurant.contactEmail}}</b>
</p>

<h2>Klager</h2>
<p>Oppdager du avvik og/eller at dataansvarlig ikke overholder dine rettigheter i henhold til GDPR kan du kontakte Datatilsynet på mailadresse: postkasse@datatilsynet.no
For å gjøre nødvendige endringer/korreksjoner raskest mulig setter vi også pris på å bli informert raskest mulig på {{currentRestaurant.contactEmail}}.</p>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="padding-bottom:6rem">
          <v-btn
            text
            @click="agreement = false, dialog = false"
          >
            Nei
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="deep-purple accent-4"
            @click="agreement = true, dialog = false"
          >
            Ja, jeg godkjenner
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    name: 'HelloWorld',

    data: () => ({
      currentTime: '',
      dialog: false,
      agreement: false
    }),

    methods: {
      async checkin() {
        try {
          await this.$store.dispatch('checkInCurrentUser')
          this.$router.push({name: 'checkedin', params: {
            //At this point, restaurantId is set
            restaurantId: this.$router.currentRoute.params.restaurantId
          }})
        } catch (err) {
          console.log(err)
        }
      }
    },

    computed: {
      ...mapState(['userProfile', 'currentRestaurant']),
      user() {
        return this.userProfile ?? null
      },
    },
  }
</script>
