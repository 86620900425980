<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5">
        <v-alert prominent type="success" :icon="false" class="py-16 px-5">
          <v-row align="center">
            <v-col cols="2" >
              <v-icon large>check</v-icon><br />
            </v-col>

            <v-col>
              Velkommen {{user.firstname}}!<br />
              Du er sjekket inn! <br /> 
              Ha en hyggelig dag videre!
            </v-col>
          </v-row>
        </v-alert>

        <!-- <v-btn @click="logout">Logg ut</v-btn> -->
    </v-card>

    <v-row>
      <v-col>
        <v-btn v-if="currentRestaurant.termsAccepted" :to="{name: 'profile'}"> 
          Min profil
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

  export default {
    name: 'HelloWorld',

    data: () => ({
      currentTime: '',
      refreshInterval: undefined
    }),

    methods: {
      logout() {
        this.$store.dispatch('logout')
        this.$router.push('/')
      }
    },
    computed: {
      ...mapState(['userProfile', 'currentCheckIn', 'currentRestaurant']),
      user() {
        return this.userProfile ?? null
      },

    },

    beforeDestroy() {
      clearInterval(this.refreshInterval)
      console.log("Interval cleared")
    },

    mounted() {
      let self = this
      // Update the count down every 1 second
      this.refreshInterval = setInterval(function() {        
        // Get today's date and time
        var now = moment();
        var then = moment(self.currentCheckIn.expires.toDate())

        // Find the distance between now and the count down date
        var distance = then - now;
        // Time calculations for days, hours, minutes and seconds
        // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(distance / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        self.currentTime = hours + "h "
        + minutes + "m " + seconds + "s ";

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(self.refreshInterval);
          self.$router.push({name: 'checkin', params: {
            //At this point, restaurantId is set
            restaurantId: self.$router.currentRoute.params.restaurantId
          }})
        }
      }, 1000);
    }
  }
</script>
