import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'

const apiKey = process.env.VUE_APP_FIREBASE_API_KEY
const firebaseConfig = {
  apiKey,
  authDomain: "smcontrol-69be4.firebaseapp.com",
  databaseURL: "https://smcontrol-69be4.firebaseio.com",
  projectId: "smcontrol-69be4",
  storageBucket: "smcontrol-69be4.appspot.com",
  messagingSenderId: "444862750364",
  appId: "1:444862750364:web:e6a8b534af80ff52589759",
  measurementId: "G-V6HLCH9L4M"
}

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const analytics = firebase.analytics()
const fb = firebase

const restaurantsCollection = db.collection("restaurants")
const guestsCollection = db.collection('guests')
const checkInCollection = db.collection('checkins')
const termsCollection = db.collection('terms')

export {
  apiKey,
  fb,
  db,
  auth,
  storage,
  analytics,
  restaurantsCollection,
  guestsCollection,
  checkInCollection,
  termsCollection
}