import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { auth } from './firebase'
import moment from 'moment'

import 'material-design-icons-iconfont/dist/material-design-icons.css'

import VueTelInput from 'vue-tel-input'

Vue.use(VueTelInput)

Vue.config.productionTip = false

let app
auth.onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
      created(){
        moment.locale('nb')
      }
    }).$mount('#app')
  }  
})

