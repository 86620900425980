<template>
  <v-container>
    Min profil
    
    <v-text-field v-model="phone" label="Telefonnummer" disabled outlined></v-text-field>
    <v-text-field v-model="firstname" label="Fornavn"></v-text-field>
    <v-text-field v-model="lastname" label="Etternavn"></v-text-field>

    <v-alert v-if="err">
      {{err}}
    </v-alert>

    <v-btn color="primary" :disabled="!profileHasChanged" :loading="loading" @click.native="update">
      <v-icon left dark>check</v-icon>
      Oppdater
    </v-btn>
    <v-divider class="mt-5" />

    <v-row v-if="activeCheckins.length > 0">
      <v-col align="left">
        <h4>AKTIVE INNSJEKK</h4>
        <v-list 
          v-for="checkin in activeCheckins"
         :key="checkin.id"
         >
         <v-list-item>
          <v-row align="center" no-gutters>
            <v-col class="text-wrap">
                 {{checkin.restaurantName}}<br />
                  Sjekket inn kl. {{formatTime(checkin.arrival)}}
            </v-col>

            <v-col cols="3">
              <v-btn
              block
              color="success"
              :to="{name: 'checkedin', params: { restaurantId: checkin.restaurantId }}"
              >
              Vis
              </v-btn>
            </v-col>
          </v-row>
         </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
        <v-col align="left">
            <v-list subheader tile>
                <v-subheader>Mine siste besøk</v-subheader>

                <v-list-item
                    v-for="checkin in history"
                    :key="checkin.id"
                >
                    <v-list-item-content>
                        <v-list-item-title v-text="checkin.restaurantName"></v-list-item-title>
                        <v-list-item-subtitle v-text="format(checkin.arrival)"></v-list-item-subtitle>
                    </v-list-item-content>

                </v-list-item>
                </v-list>
        </v-col>
    </v-row>

    
    <v-row align="center">
        <v-col>
            <v-btn block @click="logout">Logg ut</v-btn>
        </v-col>
    </v-row>
    

    <v-row align="center">
        <v-col>
            <v-btn block color="error" @click="deleteMeDialog">
                Glem meg
            </v-btn>
        </v-col>
    </v-row>

    <v-dialog
      v-model="deleteDialog"
      absolute
      max-width="400"
      persistent
    >
        <v-card>
            <v-card-title class="headline grey lighten-3">Glem meg?</v-card-title>
            <v-card-text align="left">
                <br/>

                <p>Din informasjon vil bli slettet i våre systemer senest 14 dager etter siste registrerte innsjekk. Dette av hensyn til kravene om oppbevaring av informasjon for smittesporing.</p>
                <p>Du kan når som helst velge å benytte tjenesten igjen ved å registrere din informasjon på nytt.</p>
                <p>Er du sikker på at vi skal slette informasjonen vi har lagret om deg?</p>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-btn text @click="deleteDialog = false">
                    Avbryt
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    class="white--text"
                    color="error accent-4"
                    @click="deleteMe">
                    Glem meg
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

  export default {
    name: 'HelloWorld',

    data: () => ({
      deleteDialog: false,
      firstname: '',
      lastname: '',
      phone: '',
      loading: false,
      err: '',
      now: ''
    }),

    mounted() {
      this.firstname = this.userProfile.firstname
      this.lastname = this.userProfile.lastname
      this.phone = this.userProfile.phone

      this.now = moment()
      this.fetchCheckinHistory()
    },

    computed: {
      ...mapState(['userProfile', 'currentRestaurant', 'checkinHistory', 'checkinHistoryLoading']),
      profileHasChanged() {
          return this.firstname !== this.userProfile.firstname || 
            this.lastname !== this.userProfile.lastname
      },
      history() {
          return this.checkinHistory.filter(c => {
          if(!c.expires) return true
          var then = moment(c.expires.toDate())
          return then - this.now < 0
        }).sort((a,b) => b.arrival - a.arrival)
      },
      activeCheckins() {
        return this.checkinHistory.filter(c => {
          if(!c.expires) return false
          var then = moment(c.expires.toDate())
          return then - this.now > 0
        })
      }
    },

    methods: {
      ...mapActions([
        'fetchCheckinHistory',
      ]),
      async update() {
        try {
          this.loading = true
          await this.$store.dispatch('updateProfile', {firstname: this.firstname, lastname: this.lastname})
          await this.$store.dispatch('storeUserProfile') //Store it to database
          this.loading = false
        } catch (err) {
          this.loading = false;
          this.err = err
        }
      },

      format(date) {
          return moment(date.toDate()).format('Do MMMM HH:mm')
      },
      formatTime(date) {
          return moment(date.toDate()).format('HH:mm')
      },

      deleteMeDialog() {
        this.deleteDialog = true
      },

      async deleteMe() {
        await this.$store.dispatch('deleteProfile')
        this.$router.push({name: 'login'})
      },

      logout() {
        this.$store.dispatch('logout')
        this.$router.push('/')
      },

      goToCheckin(checkin) {
        this.$router.push({name: 'checkedIn', params: {
          restaurantId: checkin.restaurantId
        }})
      }
    }
  }
</script>