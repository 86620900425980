<template>
  <div class="home">

    <h1 v-if="showName">{{currentRestaurant.name}}</h1>
    <v-row align="center" justify="center">
      <v-img :src="currentRestaurant.logoUrl" max-width="200"></v-img>
    </v-row>
    

    <router-view />

    <v-footer fixed>
      <v-spacer></v-spacer>
      <span>
        Powered by <a href="https://callida.no" target="_blank"> Callida AS </a>
        &copy; {{ new Date().getFullYear() }}
      </span>
      </v-footer>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Home',
  
  data: () => ({
    isLoggedIn: false
  }),

  methods: {
    loggedIn() {
      this.isLoggedIn = true
    }
  },

  computed: {
    ...mapState(['currentRestaurant']),
    showName() {
      return this.currentRestaurant.showName ?? true
    }
  }
}
</script>
