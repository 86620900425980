<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <!-- Oppdater din informasjon -->
      </v-card-title>

      <v-card-text>
        <div class="text--primary">Velkommen som gjest hos <b>{{currentRestaurant.name}}</b>. Før vi fortsetter trenger vi litt mer informasjon om deg:</div>
      </v-card-text>

      <v-card-text>
        <v-flex>          
          <v-text-field v-model="firstname" label="Fornavn"></v-text-field>
          <v-text-field v-model="lastname" label="Etternavn"></v-text-field>
        </v-flex>

        <v-alert v-if="err">
          {{err}}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :loading="loading" @click.native="update">
          <v-icon left dark>check</v-icon>
          Lagre
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    name: 'HelloWorld',

    data: () => ({
      firstname: '',
      lastname: '',
      phone: '',
      loading: false,
      err: ''
    }),

    mounted() {
      this.firstname = this.userProfile.firstname
      this.lastname = this.userProfile.lastname
    },

    computed: {
      ...mapState(['userProfile', 'currentRestaurant'])
    },

    methods: {
      async update() {
        try {
          this.loading = true
          await this.$store.dispatch('updateProfile', {firstname: this.firstname, lastname: this.lastname}) 
          this.$router.push({name: 'checkin', params: {
            //At this point, restaurantId is set
            restaurantId: this.$router.currentRoute.params.restaurantId
          }})
        } catch (err) {
          this.loading = false;
          this.err = err
        }
        
      }
    }
  }
</script>
