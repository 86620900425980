<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5">
      
      <v-card-title>
        <h1 class="display-1">Logg inn</h1>
      </v-card-title>

      <v-form @submit.prevent>

        <template v-if="!showVerification">
        
          <v-card-text>
              <!-- <v-text-field prepend-icon="mdi-cellphone" label="Phone number" type="phone" v-model="phone"/> -->
              <vue-tel-input v-model="phone" defaultCountry="no" v-on:country-changed="countryChanged" :inputOptions="options"></vue-tel-input>

              <!-- <v-checkbox v-model="termsAccepted">
                <template v-slot:label>
                  <div>Jeg har lest og aksepterer <a href="#">personvernerklæringen</a></div>
                </template>
              </v-checkbox> -->

          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn id="sign-in-button" :disabled="!checkindisabled">Check-In</v-btn>
          </v-card-actions>

        </template>

        <template v-if="showVerification">
          
          <v-card-text>
            <p>
            Vi har sendt deg en bekreftelseskode på SMS
            </p>

            <v-text-field prepend-icon="mdi-cellphone" label="Kode" type="phone"  v-model="smsCode"/>
            <v-btn @click="handleVerification()">Verifiser</v-btn>
          </v-card-text>
        </template>

        <v-alert type="error" v-if="errorCode">
          {{translateError(errorCode)}}
        </v-alert>

        <v-alert type="error" v-if="error">
          {{error}}
        </v-alert>


      </v-form>
    </v-card>
  </v-container>
</template>

<style>
/* .grecaptcha-badge { 
    visibility: hidden;
} */
</style>

<script>
/* eslint-disable no-debugger, no-console */
import * as fb from '../firebase'

  export default {
    name: 'HelloWorld',

    data: () => ({
      countryCode: '',
      phone: '',
      smsCode: '',
      termsAccepted: false,
      showVerification: false,
      confirmationResult: {},
      errorCode: '',
      error: '',
      options: {
        showDialCode: true
      }
    }),
    mounted() {
      const self = this
      fb.auth.languageCode = 'no';
      window.recaptchaVerifier = new fb.fb.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': function() {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          self.handleRecaptcha();
        }
      })

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    },

    computed: {
      checkindisabled() {
        return this.phone != '' // && this.termsAccepted
      },
    },

    methods: {

      firebaseApiKey() {
        console.log(fb.apiKey)
        return fb.apiKey
      },

      countryChanged(country) {
        this.countryCode = country.dialCode
      },

      translateError(errCode) {
        switch(errCode) {
          case "auth/invalid-phone-number":
            return 'Telefonnummeret er ikke gyldig. Vennligs angi et gyldig telefonnummer'
        }
      },

      async handleRecaptcha() {
        var self = this
        var appVerifier = window.recaptchaVerifier;
        let cc = "+" + this.countryCode
        this.phone = this.phone.trim()
        if(this.phone.substring(0, cc.length) !== cc) this.phone = cc + this.phone
        try {
          this.confirmationResult = await fb.auth.signInWithPhoneNumber(this.phone, appVerifier);
          this.showVerification = true
        } catch(err) {
          console.log(err)
          self.error = err
          self.errorCode = err.code
          window.recaptchaVerifier.clear()
        }
      },

      async handleVerification() {
        if(this.smsCode === '') alert("NEI!!")

        try {
          await this.confirmationResult.confirm(this.smsCode)
          // this.$store.dispatch('login', confirmResult.user);
          // this.$emit("loggedin")
          if(this.$router.currentRoute.params.restaurantId){
            this.$router.push({name: 'checkin', params: {
              restaurantId: this.$router.currentRoute.params.restaurantId
            }})
          } else {
            this.$router.push({name: 'profile'})
          }
          window.recaptchaVerifier.clear()
        } catch(err) {
          window.recaptchaVerifier.clear()
          console.log(err)
          throw err
        }
        
        
        
      }
    }
  }
</script>
